import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const MerchPage = () => {
	return (
		<Layout>
			<Seo title="Now" />
			<h2>Now</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/Jesse_Rivest_Toadstool_007.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={500}
				/>
			</div>

			<h3>What am I doing now?</h3>
			<p>
				Updated May 20, 2024.<br />
				(This is a <span className="italic">now page</span> in the style of Derek Sivers.)
			</p>
			<h4>Still looking for a job</h4>
			<p>
				Because of my split-based situtation (between the capital of Brazil and my hometown, Kelowna), I need some good work.
				What does that mean?
				Well, steady and reliable work, and ideally well-paying work.
				I guess we all want that, eh?
				It's just that it's quite expensive going between countries and maintaining a modest home in one of them;
				also, <span className="bold">my music touring and production is all on hold</span> until my finances are <span className="italic">back in black</span>.
				My situation and my existing experience sort of squeeze me into one area of work: <span className="italic">software engineering</span>.
				<br />
				That's what I'm looking for: a software engineering role.
				<br />
				<Link to="/contact/">Get in touch</Link> if you think you can help 😎.
			</p>
			<h4>Learning AdonisJS</h4>
			<p>
				AdonisJS has nothing to do with music, but it looks very exciting and promising for software application development.
				I've used Laravel before and AdonisJS seems almost identical, with the bonus of getting to work in the Node.js ecosystem.
				So, since I'm looking for work as a software engineer, you might understand why I am working on a personal project to learn AdonisJS.
				<br />
				Call it upskilling.
			</p>
			<h4>Livestreaming</h4>
			<p>
				<span className="bold">Back to music, which is what this website is about</span>.
				I've been livestreaming a lot lately and will likely continue (after perhaps a month's break)!
				It's been great for delving deeper into my repertoire; as well, it's been allowing me to analyze my performances and think more developmentally about them.
				I also got to scratch my photography itch by setting up my modern micro-four-thirds camera with one of my vintage Nikon full-frame lenses;
				add some warm, controlled lighting and... voilá!
				My livestream actually looks pretty sweet.
				<br />
				If you're curious, check out <a href="//www.youtube.com/@jesserivest/live/">Youtube</a> or <a href="//www.ursalive.com/jesserivest">Ursa Live</a> to see if I have any scheduled livestreams upcoming.
			</p>
			<h4>Going to the beach</h4>
			<p>
				My partner, Jane, needs to take some holidays soon.
				The past two years we've used up essentially all of her holiday time being in Canada;
				as such, she's really missing the road trips we used to make to the coast of Brazil.
				I miss them, too!
				I may be broke right now, but she needs a driver and a loving companion to share the adventure with...
				<br />
				So, I'll be going to the beach for a bit!
			</p>
			<h4>Itching to songwrite</h4>
			<p>
				Songwriting has been on hold for a long time.
				The itch is strong now.
				Each week I'm trying to reassess my priorities so I can get this back into my weekly schedule.
				Some local friends are sending me stuff to work on and I'm not getting around to it;
				I have a new friend in Canada that I want to cowrite with but I've so far sent him nothing;
				my own stack of finished, unreleased songs could use some revisiting and revision; and also, I like to say:
				<br />
				I need to try them on.
				<br />
				I need to learn the songs I wrote, interpret them and establish myself in them, and from there...
				I do need some money if I am to produce and release them.
			</p>

		</Layout>
	);
}

export default MerchPage;
